.thankYouPage {
  border-top: solid lightgray 3px;
  .thankYouText {
    padding: 100px 0 200px 0;
    width: 70vw;
    margin: auto;
    @media (max-width: 850px){
      padding: 40px 0 80px 0;
    }

    a {
      text-decoration: none;
      color: #BA0C2F;

    }
    a:hover{
      text-decoration: underline;
    }

    button {
      background: #BA0C2F;
      border-radius: 12px;
      border-style: none;
      color: white;
      width: 17rem;
      height: 3rem;
      padding: 0 30px;
      margin: 20px 0;
      text-align: center;
      font-weight: 700;
      font-size: 1.2rem;
    }

    .thankYouH1 {
      margin-bottom: 60px;
    }

    h4 {
      word-wrap: break-word;
      font-size: 20px;
      color: black;
      line-height: 50px;
      //padding: 0 90px;
    }
  }
  .homeImgLineBox {
   margin: 20px 0;

    .imgLine-1 {
      height: 11px;
      width: 196px;
      background-color: #950B33;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      margin-right: auto;
    }

    .imgLine-2 {
      height: 11px;
      width: 145px;
      background-color: #950B33;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      margin-right: auto;
      margin-bottom: 10px;
    }

    .imgLine-3 {
      height: 11px;
      width: 94px;
      background-color: #950B33;
      background-clip: content-box;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      margin-bottom: 10px;
      margin-right: auto;
    }
  }
    .thankYouBottomLineBox {
      .thankYouBottomLine-1 {
        margin-bottom: 10px;
        height: 11px;
        width: 145px;
        background-color: #950B33;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        margin-left: auto;
      }

      .thankYouBottomLine-2 {
        height: 11px;
        width: 196px;
        background-color: #950B33;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        margin-left: auto;
        margin-bottom: 10px;
      }
    }
}