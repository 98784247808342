.homePage {
  display: flex;
  flex-direction: column;
  z-index: 0;

  button {
    background: #BA0C2F;
    border-radius: 8px;
    border-style: none;
    color: white;
    size: auto;
    padding: 10px 30px;
    text-align: center;
    font-weight: 700;
  }

  a:link {
    text-decoration: none;
  }

  .homeTopSection {
    height: 45%;
    background-color: #D2D2D2;
    display: flex;
    flex-direction: row;
    z-index: 1;
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
    }

    .textAndLines {
      display: flex;
      flex-direction: row;
      @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
      }

      .homeTopLineGraphic {
        position: relative;
        padding: 5%;
        justify-content: center;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        @media (max-width: 600px) {
          padding: 0;
        }

        .homeLineBox {
          position: absolute;
          top: 15px;
          left: 0;
        }

        .homeLine-1 {
          height: 11px;
          width: 145px;
          background-color: #950B33;
          margin-bottom: 10px;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }

        .homeLine-2 {
          height: 11px;
          width: 196px;
          background-color: #950B33;
          margin-bottom: 10px;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }

        .homeLine-3 {
          height: 11px;
          width: 271px;
          background-color: #950B33;
          background-clip: content-box;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          margin-bottom: 20px;
        }
      }

      .homeTopSectionText {
        width: 60%;
        padding: 200px 50px;
        @media (max-width: 800px) {
          padding: 100px 0 50px;
          width: 100%;

        }

        h1 {
          margin: auto;
          font-size: 3rem;
          @media (max-width: 600px) {
            font-size: 1.8rem;
          }
        }

        .topRedH1 {
          color: #BA0C2F;
        }

        .topP {
          margin: 40px auto;
          font-size: 1.25rem;
          max-width: 80%;
        }

        .topH2 {
          max-width: 80%;
          margin: auto;
        }
      }

      .homeBottomLineBox {
        height: 75px;

        .imgLine-1 {
          @media (max-width: 800px) {
            height: 11px;
            width: 145px;
            background-color: #950B33;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            margin-left: auto;
          }
        }

        .imgLine-2 {
          @media (max-width: 800px) {
            height: 11px;
            width: 196px;
            background-color: #950B33;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            margin-left: auto;
            margin-bottom: 10px;
          }
        }

        .imgLine-3 {
          @media (max-width: 800px) {
            height: 11px;
            width: 145px;
            background-color: #950B33;
            background-clip: content-box;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            margin-bottom: 10px;
            margin-left: auto;
          }
        }
      }
    }

    .topSectionImage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 40%;
      @media (max-width: 800px) {
        display: none;
      }

      .topSectionImage > img {
        margin: 0;
      }

      .homeImgLineBox {
        margin-bottom: 20px;

        .imgLine-1 {
          height: 11px;
          width: 80%;
          background-color: #950B33;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          margin-left: auto;
        }

        .imgLine-2 {
          height: 11px;
          width: 90%;
          background-color: #950B33;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          margin-left: auto;
          margin-bottom: 10px;
        }

        .imgLine-3 {
          height: 11px;
          width: 100%;
          background-color: #950B33;
          background-clip: content-box;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          margin-bottom: 10px;
          margin-left: auto;
        }
      }
    }

    .nominateButton {
      display: flex;
      margin: 40px auto auto auto;
      font-size: 1.535rem;
      cursor: pointer;
      max-width: 80%;
    }
  }

  .homeBottomSection {
    position: relative;
    height: 55%;
    overflow: clip;

    .dotGraphic {
      .dotGraphicImg {
        position: absolute;
        left: -90px;
        top: -500px;
        z-index: -1;
      }
    }

    h2{
      @media (max-width: 600px){
        margin: 30px auto;
        max-width: 95%;
      }
    }

    .checkOutOurHeroesSection {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      @media (max-width: 800px) {
        flex-direction: column;
      }

      .checkOutSectionImage {
        .heroSectionImg {
          max-width: 50%;
          margin: 40px auto auto;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          @media (max-width: 600px) {
            margin-top: 40px;
          }
        }
      }

      .checkOutSectionText {
        display: flex;
        flex-direction: column;
        justify-items: center;
        width: 50%;
        margin: auto;
        padding-right: 40px;
        text-align: left;
        font-weight: 500;
        line-height: 36px;
        @media (max-width: 600px) {
          padding-right: 0;
          width: 100%;
          p{
            max-width: 60%;
            margin: 10px auto;

          }
        }

        .checkOutButton {
          display: flex;
          font-size: 20px;
          margin: auto;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .testimonialSection {
      width: 100vw;
      height: 50%;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .testimonialContainer {
        width: 50%;
        margin-left: auto;
      }

      .testimonials {
        max-width: 100vw;
        display: flex;
        transition: transform 0.3s;
      }

      .arrowBox {
        align-self: center;
        width: 100vw;
        box-sizing: border-box;
        position: absolute;
        display: flex;
        justify-content: space-between;
        font-family: 'Montserrat', sans-serif;
        opacity: 40%;
        z-index: 1;
        cursor: pointer;

        .leftArrow {
          margin-left: 50px;
          user-select: none;

          .leftArrowButton {
            font-size: 100px;
            font-weight: normal;
            color: black;
            margin: 0;
            padding: 0;
            background-color: transparent;
            @media (max-width: 480px) {
              font-size: 60px;
            }
          }
        }

        .rightArrow {
          margin-right: 50px;
          user-select: none;

          .rightArrowButton {
            font-size: 100px;
            font-weight: normal;
            color: black;
            margin: 0;
            padding: 0;
            background-color: transparent;
            @media (max-width: 480px) {
              font-size: 60px;
            }
          }
        }
      }
    }

    .bottomLineGraphicSection {
      padding-top: 70px;
      width: 100vw;
    }
  }
}