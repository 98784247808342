.heroes-listing{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-top: 15px;
}
.no-heroes-message {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    color: black;
}

a:visited {
    color: black;
}

.load-more-button {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: white;
    background-color: gray;
    height: 42px;
    width: 150px;
    border-radius: 11px;
    margin-bottom: 50px;
    border: none;
    cursor: pointer;
}

.red-bar-bottom {
    border: none;
    width: 360px;
    height: 53px;
    right: 0;
    align-content: end;
    border-radius: 0;

}

.red-bar-bottom-div {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 500px) {
    .red-bar-bottom{
        display: none;
    }
}
