.nominationForm {
  position: relative;
  height: fit-content;
  padding: 0 15vw;
  font-weight: 700;
  font-family: Montserrat, sans-serif;

  button {
    margin-top: 20px;
    background: #BA0C2F;
    border-radius: 15px;
    border-style: none;
    color: white;
    width: 20vw;
    height: 100%;
    padding: 10px 30px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
  }

  .nomineeInfoSection {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: space-between;
    width: 75vw;

    div {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 30px 0;

      input {
        min-width: 30rem;
        height: 3rem;
        margin-top: 10px;
        font-size: 1rem;
      }
      input::placeholder {
        font-style: italic;
      }
    }
  }

  .nominationDescriptionSection {
    width: 75vw;
    text-align: left;

    textarea {
      width: 100%;
      height: 20rem;
      font-family: Montserrat, sans-serif;
      margin-top: 10px;
      line-height: 22px;
    }
    textarea::placeholder {
      font-style: italic;
    }
  }

  .nominationCategorySection {
    p {
      font-weight: 500;
      font-style: italic;
      color: #54585A;
    }

    select {
      width: 30vw;
      height: 5vh;
      padding: 0 10px;
      font-family: Montserrat, sans-serif;
      cursor: pointer;
    }
  }

  .uploadSection {
    .uploadBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 22vw;
      min-height: 20vh;
      border: black solid 1px;
      margin: auto;
      font-weight: 700;

      button {
        position: relative;
        z-index: 2;
        margin-top: 10%;
        color: white;
        font-weight: 700;
        font-family: Montserrat, sans-serif;
        font-size: 1.2rem;
        border-color: transparent;
        background-color: black;
        width: 80%;
        height: 30%;
        border-radius: 15px;
        pointer-events: none;
      }

      h3 {
        position: relative;
        z-index: 2;
        font-size: 1rem;
        pointer-events: none;
      }

      input {
        z-index: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: transparent;
        position: absolute;
        cursor: pointer;
      }

      .whiteRect {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: white;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      .image-preview{
        height: 100%;
        width: 100%;
        z-index: 100;
        left: 0;
      }

    }

    .redBorder {
      border: #BA0C2F dashed 1px;
      color: transparent;
    }
  }

  .submitterInfoSection {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 30px 0;

      input {
        min-width: 30rem;
        height: 3rem;
        margin-top: 10px;
        font-size: 1rem;
      }
      input::placeholder {
        font-style: italic;
      }
    }
  }
}

.nominateBottomLineBox {
  position: relative;
  bottom: 0;
  right: 0;

  .nominateBottomLine-1 {
    margin-bottom: 10px;
    height: 11px;
    width: 145px;
    background-color: #950B33;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: auto;
  }

  .nominateBottomLine-2 {
    height: 11px;
    width: 196px;
    background-color: #950B33;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin-left: auto;
    margin-bottom: 10px;
  }
}

@media (max-width: 850px) {
  .nominationForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .nomineeInfoSection {
      display: flex;
      flex-direction: column;

      div {
        input {
          min-width: 75vw;
        }
      }
    }

    .submitterInfoSection {
      flex-direction: column;

      div {
        input {
          min-width: 75vw;
        }
      }
    }

    .uploadSection {
      .uploadBox {
        width: 60vw;
      }
    }

    button {
      width: 30vw;
      height: 100%;
      padding: 10px 30px;
      margin-bottom: 20px;
      font-size: 1.1rem;
    }
  }
  .nominateBottomLineBox {
    display: none;
  }
}
@media (max-width: 600px) {
  .nominationForm{
    button {
      width: 80%;
    }
  }
}