
.award-info h1{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    color: white;
    margin-top: 0;
}

.award-info p{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: white;
    margin-top: 0;
}

.learnMore {
    width: 130px;
    height: 48px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #BA0C3F;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
    margin-right: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.learnMore:visited{
    color: #BA0C3F;
}
.awardImage{
    display: block;
    flex: 0 0 auto;
    border: 0.4em solid #000000;
    box-sizing: border-box;
    border-radius: 18px 18px 0 0;
    width: 450px;
    height: 250px;
    object-fit: cover;
    object-position: center top;
}
.individual-award{
    display: flex;
    flex-direction: column;
    background-color: black;
    border-radius: 18px 18px 18px 18px;
    margin-bottom: 2.7em;
}
.award-info{
    display: flex;
    flex-direction: column;
    justify-self: left;
    margin: 1.3em 0 1.3em 1.3em ;
    max-width:230px;
}
.award-listing{
    margin: 0;
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    border-bottom-right-radius: 2em;
    border-bottom-left-radius: 2em;

}

@media (max-width: 500px) {
    .individual-award{
        height: fit-content;
    }
    .individual-award > img {
        width: 90vw;
        height: calc(90vw/1.6);
    }
    .award-info {
        margin-left: 10px;
        margin-bottom: 5px;
        margin-right: 5px;
    }
    .award-info h1{
        font-size: 1.1rem;
    }
    .award-info p{
        font-size: 1rem;
    }

    .learnMore{
        margin-right: 10px;
        height: 48px;
        width: 30%;
        font-size: 15px;
    }

}