#root > footer {
    display: flex;
    margin-bottom: 0;
    position: sticky;
    top: 100vh;
}
.footer{
    display: block;
    bottom: 0;
    top: 100vh;
    position: sticky;
    margin-top: 25px;
    border-top: 3px solid lightgray;
    z-index: 0;

    .nav-footer{
        background-color: white;
        min-height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 100px;

        .nominate-footer{
            text-decoration: none;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 24px;
            background: #BA0C2F;
            border-radius: 13px;
            color: white;
            padding: .5em;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 50px;
        }
        .nav-not-nominate{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }
        .nav-not-nominate {
            a{
                color: black;
                text-decoration: none;
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 24px;
                margin-right: 5vw;
            }
            a:last-child{
                margin-right: 0;
            }
        }
    }
    .red-bar-footer{
        display: none;
    }
}

@media (max-width: 825px){
    .footer{
        .nav-footer{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px 0;
            .nav-not-nominate{
                display: flex;
                flex-direction: column;
            }
            .nav-not-nominate >a{
                margin-right: 0;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
        .red-bar-footer{
            margin-left: calc(100vw - 150px);
            display: flex;
            overflow: hidden;
            margin-bottom: 15px;
        }

    }
}

