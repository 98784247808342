.awardsPage{
    color: white;
    font-family: 'Montserrat', sans-serif;
}
.awardsText {
    position: relative;
    background-color: #808588;
    height: 353px;
    justify-content: center;
}
.awardsText > h1{
    size: auto;
    position: absolute;
    line-height: 59px;
    font-size: 48px;
    left: 21.5vw;
    top: 73px;
}
.awardsText > p{
    text-align: left;
    line-height: 32px;
    font-size: 18px;
    position: absolute;
    top: 149px;
    left: 21.5vw;
    right: 18.5vw;
    bottom: 6.1vh ;
}
.awardBoxes{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 4em 1em 1em 1em;
}
.seeMoreButton{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: white;
    background-color: gray;
    height: 42px;
    width: 150px;
    border-radius: 11px;
    margin-bottom: 50px;
    border: none;
}
.seeMoreDiv{
    position: relative;
}

@media (max-width: 500px) {
    .bottomLineBox{
        display: none;
    }
}
