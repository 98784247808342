.individual-hero > img {
    border: 4px solid #000000;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 18px 18px 0 0;
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center top;
}

.individual-hero {
    background-color: black;
    border-radius: 18px;
    margin: 15px;
}

@media (min-width: 600px) {
    .individual-hero {
         width: 29%;
    }
}

.hero-info {
    margin-left: 10px;
}

.hero-listing {
    display: flex;
    justify-content: space-between;
}

.hero-listing h1 {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    color: white;
}

.hero-listing p {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: white;
    margin-bottom: 20px;
}

.learn-more {
    width: 130px;
    height: 48px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    color: #BA0C3F;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
    margin-right: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.learn-more:visited {
    color: #BA0C3F;
}

@media (max-width: 500px) {
    .individual-hero {
        height: fit-content;
    }

    .individual-hero > img {
        width: 90vw;
        height: calc(90vw / 1.6);
    }

    .hero-info {
        margin-left: 10px;
        margin-bottom: 5px;
    }

    .hero-info h1 {
        font-size: 1.1rem;
    }

    .hero-info p {
        font-size: 1rem;
    }

    .learn-more {
        margin-right: 10px;
        height: 48px;
        width: 30%;
        font-size: 15px;
    }

}