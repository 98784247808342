.header {
  display: flex;
  justify-content: space-between;

  .logo {
    border: none;
    width: 200px;
    height: 125px;
    margin-left: 10vw;
    align-content: center;
    margin-top: 20px;
    margin-right: 10px;
    @media (max-width: 600px) {
      margin: 0;
      justify-content: center;
      display: flex;
    }
  }

  nav {
    background-color: white;
    min-height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  a {
    color: black;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-right: 3vw;
  }

  .nominate {
    background: #BA0C2F;
    display: flex;
    border-radius: 8px;
    color: white;
    padding: .4em;
    text-align: center;
    margin-right: 100px;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 825px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin-right: 0;
      margin-bottom: 8px;
    }

    nav {
      flex-direction: column;
      margin-bottom: 25px;
    }

    .nominate {
      margin: 0;
      height: 33px;
    }

    .logo {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}