.topSection {
    position: relative;
    background-color: #808588;
    padding: 1% 0 1% 0;
    color: white;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;

    .lineBox {
        position: absolute;
        top: 15px;
        left: 0;

        .line-1 {
            height: 11px;
            width: 145px;
            background-color: #950B33;
            margin-bottom: 10px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }

        .line-2 {
            height: 11px;
            width: 196px;
            background-color: #950B33;
            margin-bottom: 10px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }

        .line-3 {
            height: 11px;
            width: 271px;
            background-color: #950B33;
            background-clip: content-box;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            margin-bottom: 40px;
        }
    }

    .homeTopSectionText > h1 {
        text-align: left;
        line-height: 59px;
        font-size: 3rem;
        max-width: 55%;
        margin: .8em auto;
        font-weight: 700;
        padding-top: 45px;
        @media (max-width: 600px) {
            text-align: center;
            margin: .8em auto;
            max-width: 90%;
        }
    }

    .homeTopSectionText > p {
        margin: auto;
        text-align: left;
        font-size: 20px;
        font-family: 'Montserrat',sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 175%;
        max-width: 55%;
        bottom: auto;
        padding-bottom: 25px;
        @media (max-width: 600px) {
            text-align: center;
            max-width: 80%;
        }
    }
}