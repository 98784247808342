.testimonialBox{
  transform: translateX(calc(-50% - 20px));
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #D2D2D2;
  padding: 100px 40px 50px 40px;
  box-sizing: border-box;
  min-width: 50vw;
  min-height: 40vh;
  color: white;
  margin: 0 20px;
  border-radius: 35px;
  font-size: .8rem;
  @media (max-width: 850px) {
    padding: 40px 20px 50px 20px;
    max-height: 50vh;
    min-width: 80vw;
  }

  .quoteGraphic{
    position: absolute;
    margin: 0;
    justify-content: left;
    top: 0;
    left: 30px;
    .quoteH1 {
      font-size: 100px;
      font-family: 'Tauri', serif;
      font-weight: 400;
      color: #BA0C2F;
      margin: auto;
      @media (max-width: 700px) {
        font-size: 100px;
      }
    }
  }
  .quote{
    margin: auto;
    h2{
      font-size: 1.4rem;
      margin-bottom: 40px;
      @media (min-width: 600px) and (max-width: 850px){
        margin-bottom: 15px;
      }
      @media (max-width: 600px) {
        font-size: 1.1rem;
        margin: 15px auto;
      }
    }
  }
  .nameAndTitle{
    margin: 0;
    text-align: right;
    text-transform: uppercase;
    h2{
      margin: 0;
      font-size: 1.2rem;
      max-width: 100%;
      @media (min-width: 600px) and (max-width: 850px){
        font-size: 1rem;
      }
      @media (max-width: 600px) {
        font-size: .9rem;
      }
    }
    @media (max-width: 850px) {
      margin-right: 0;
    }
    .nameAndTitle > h2{
      margin: 0;
    }
    .title{
      margin: 0;
      font-weight: 400;
    }
  }
}
