.about {
  margin: 0;
  padding: 0;

  h2 {
    text-align: left;
    padding-top: 50px;
    padding-bottom: 25px;
  }

  ul {
    list-style-type: none;
  }

  section {
    width: 90vw;
    max-width: 1024px;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .members {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content:space-between;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  .members .member h3 {
    padding-top: 10px;
  }

  .members .member a,
  .members .member p {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }

  .members .member img {
    -o-object-fit: contain;
    object-fit: contain;
    height: 210px;
    width: 190px;
    border-radius: 8px;
  }

  .partners {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

  }

  .partners .partner h3 {
    padding-top: 10px;
  }

  .partners .partner a {
    font-size: 12px;
    font-weight: 200;
  }

  .partners .partner img {
    -o-object-fit: contain;
    object-fit: contain;
    height: auto;
    width: 190px;
    border-radius: 8px;
  }

}

/*# sourceMappingURL=About.css.map */