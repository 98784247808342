.bottomLineBox{
    position: absolute;
    bottom: 0;
    right: 0;
}
.bottomLine-1{
    height:11px;
    width: 145px;
    background-color: #950B33;
    border-bottom-left-radius:10px ;
    border-top-left-radius:10px;
    margin-left: auto;
}
.bottomLine-2{
    height: 11px;
    width: 196px;
    background-color: #950B33;
    border-bottom-left-radius:10px ;
    border-top-left-radius:10px;
    margin-left: auto;
    margin-bottom: 10px;
}
.bottomLine-3{
    height: 11px;
    width: 271px;
    background-color: #950B33;
    background-clip: content-box;
    border-bottom-left-radius:10px ;
    border-top-left-radius:10px;
    margin-bottom: 10px;
    margin-left: auto;
}